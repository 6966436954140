/* eslint-disable max-len */
import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': 'linear-gradient(90deg, #2A4FD3 0%, #7540C1 20.31%, #BF43BF 39.58%, #D137A9 60.94%, #F42A78 81.77%, #F24455 100%)',
		'primary-dark': 'linear-gradient(90deg, #2A4FD3 0%, #7540C1 20.83%, #BF43BF 40.63%, #D137A9 61.46%, #F42A78 80.21%, #F24455 99.48%)',
		'accent': '244, 42, 120',
		'accent-plus': '255, 255, 255',
	},
});
